import React from "react"
import { Helmet } from "react-helmet"
import { defaults } from "react-chartjs-2"
import { Link } from "gatsby"
import CTDataLogo from "../images/ctdata.png"
import PSCLogo from "../images/psc.png"
import favicon from "../images/favicon.png"
import pschouses from "../images/pschouses.png"

// Make sure all bar charts start at 0
defaults.bar.scales.yAxes[0]['ticks'] = {}
defaults.bar.scales.yAxes[0].ticks.beginAtZero = true
defaults.bar.maintainAspectRatio = false
defaults.global.defaultFontSize = 13

export default function Layout({children}) {

  return <>
  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Housing Data Profiles – Connecticut</title>
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>

    <section className="mw8 pa3 center helvetica">

      <div className="cf">

        <a href="https://www.pschousing.org/" target="_blank" rel="noreferrer" className="">
          <img src={PSCLogo} className="dim h3" alt="PSC Logo" />
        </a>

        <img src={pschouses} className="ml2" alt="PSC Houses" style={{height: '50px'}} />

        <h1 className="f2 f1-l mb2 mt0 pt0 white pa1" style={{backgroundColor: '#1f3365'}}>
          <Link to="/" className="link white">
            Housing Data Profiles
          </Link>
        </h1>
       
        <h2 className="f3 f2-l mt0 mb2 measure-wide fl-ns w-100 w-75-ns" style={{color: '#1f3365'}}>
          Data on housing and affordability for each of
          Connecticut's 169 towns and cities
        </h2>

        <div className="w-100 w-25-ns tl tr-ns f4 black-20 b fl-ns">
        Created by <br/>
        <a href="https://ctdata.org/" target="_blank" rel="noreferrer">
          <img src={CTDataLogo} className="dim ml3" alt="CTData Logo" style={{maxHeight: '50px'}} />
        </a>
        <br></br>
        2022
      </div>

      <div className="cf"></div>

      <p className="f6 mt0 pt0 silver i fl">
        Funded through support from Fairfield County’s Community Foundation and Liberty Bank Foundation
      </p>

      </div>


      
      {children}
    </section>

    <footer className="mt4 helvetica bg-near-white black-80">
      <div className="mw8 center pa3 cf">

        <div className="w-100 w-two-thirds-ns fl-ns">
          <h3 className="f4">About</h3>

          <p className="measure-wide">
            The Partnership for Strong Communities's Housing Data Profiles are a free
            resource to help Connecticut residents, developers, legislators, municipal
            officials, and others make data-informed decisions.
            Profiles are available for every town in the state, as well as each county,
            and the state as a whole.
          </p>

          <p className="measure-wide">
            Created by <a href="https://ctdata.org/" className="link black-80 underline dim">CTData Collaborative</a> for
            the <a href="https://www.pschousing.org/" className="link black-80 underline dim">Partnership for Strong Communities</a> &copy; 2022.
          </p>
        </div>
        

        <div className="w-100 w-third fl-ns">
          <h3 className="f4">Related Links</h3>

          <p className="measure-wide">
            <a href="https://www.pschousing.org/" className="link blue dim">Partnership for Strong Communities</a>
          </p>

          <p className="measure-wide">
            <a href="https://profiles.ctdata.org/" className="link blue dim">CERC Town Profiles</a>
          </p>

        </div>

      </div>
    </footer>

  </>

}